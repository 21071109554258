/* --- MOBILE STYLES --- */

.landing .name {
  font-size: 48px;
}
.landing .description {
  font-size: 22px;
}

.landingImage {
  right: 0px;
}

.landing .socialIcons {
  display: none;
}

.landing .socialIcons {
  display: flex;
  position: absolute;
  left: 50px;
  bottom: 20px;
}

.downloadCV {
  margin-top: var(--sm-spacing);
  margin-bottom: var(--sm-spacing);
}

.button {
  font-size: 16px;
}

.tsparticles {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  z-index: -1; /* if you use -1 you have to set to `"window"` the interactivity.detectsOn property */
  height: 100%;
}


/* --- MEDIA QUERIES --- */

@media (min-width: 750px) {
  .landing .name {
    font-size: 70px;
  }

  .landing .description {
    font-size: 32px;
  }

  .landingImage {
    bottom: 0;
    right: 80px;
  }

  .landing .socialIcons {
    flex-direction: column;
  }

  .button{
    font-size: 22px;
  }
}
